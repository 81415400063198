import React from 'react';
// import { Link } from "gatsby"

import Layout from '../components/layout';
import Seo from '../components/seo';

const AboutPage = () => (
  <Layout bg="bg-peak">
    <Seo title="Über Uns" />
    <div data-sal="slide-up" data-sal-delay="200" data-sal-easing="ease" data-sal-duration="500" className="pt-32 boxed generic-page">
      <h2>
        Vielen Dank für Ihre Nachricht.
        <br />
        Wir werden Ihre Anfrage so schnell wie möglich bearbeiten.
      </h2>
    </div>
  </Layout>
);

export default AboutPage;
